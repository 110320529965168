import React from "react";
import './DataSection.scss';

function DataSection(props: any) {
    const {title, children} = props;
    return(
        <div className="data-section-container">
            <div className="data-section">
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    );
}

export default DataSection;