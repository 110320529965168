import React from 'react';
import {AuthenticatedTemplate, useMsal, UnauthenticatedTemplate} from "@azure/msal-react";
import {loginRequest} from "../../authConfig";

function LandingPage() {
    const { instance } = useMsal();

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    return(
        <div style={{paddingTop: "80px"}}>
            <AuthenticatedTemplate>
                {JSON.stringify(instance.getAllAccounts())}
                <button onClick={handleLogoutRedirect}>logout</button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <button onClick={handleLoginRedirect}>login</button>
            </UnauthenticatedTemplate>
        </div>
    )
}

export default LandingPage;