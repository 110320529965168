import React, {createContext, useEffect, useState} from 'react';
import './App.scss';
import {Route, Routes} from "react-router-dom";
import AnalysisPage from "./pages/AnalysisPage/AnalysisPage";
import {useMsal} from "@azure/msal-react";
import LandingPage from "./pages/LandingPage/LandingPage";
import PreLandingPage from "./pages/LandingPage/PreLandingPage";
import Navbar from "./components/Navbar/Navbar";
import {config} from "./config";
import {ModalContextData} from "./models/ModalContextData";
import Modal from "./components/Modal/Modal";
import Datenschutzerklaerung from "./pages/LegalPages/Datenschutzerklaerung";
import Impressum from "./pages/LegalPages/Impressum";
import Nutzungsbedingungen from "./pages/LegalPages/Nutzungsbedingungen";

export const ModalContext = createContext<ModalContextData>({});

function App() {
    const {instance} = useMsal();
    const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);
    const [modalContext, setModalContext] = useState<ModalContextData>({});

    useEffect(() => {
        //instance.acquireTokenSilent(loginRequest).then().catch((error) => instance.logout());
    }, [instance]);

    useEffect(() => {
        if (!cookiesAccepted && (!localStorage.getItem('cookies_accepted') || localStorage.getItem('cookies_accepted') == '0')) {
            showCookieModal();
        }
    }, []);

    useEffect(() => {
        if(cookiesAccepted) {
            localStorage.setItem('cookies_accepted', '1');
            setModalContext({});
        }
    }, [cookiesAccepted]);

    const showCookieModal = () => {
        setModalContext({...config.cookieBanner, buttonCallback: () => setCookiesAccepted(true), show: true});
    }

    return(
        <>
            <ModalContext.Provider value={modalContext}>
                <Modal/>
                <Navbar/>
                <div className="App">
                    <Routes>
                        {
                            !config.preRegistration &&
                            <Route path='/analysis' element={<AnalysisPage/>}/>
                        }
                        <Route path='/datenschutz' element={<Datenschutzerklaerung/>}/>
                        <Route path='/nutzungsbedingungen' element={<Nutzungsbedingungen/>}/>
                        <Route path='/impressum' element={<Impressum/>}/>
                        <Route path='*' element={config.preRegistration ? <PreLandingPage/> : <LandingPage/>}/>
                    </Routes>
                </div>
            </ModalContext.Provider>
        </>
    );
}

export default App;
