export const config = {
    preRegistration: true,
    mockAssetData: false,
    mockProphetData: false,
    mockTimeSeries: false,
    mockNewsData: false,

    cookieBanner: {
        text: "Um Ihnen ein bestmögliches Online-Erlebnis zu bieten, verwenden wir Cookies und ähnliche Technologien. Durch die Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies sowie unseren Datenschutzbestimmungen und Nutzungsbedingungen einverstanden.<br>" +
            "<br>" +
            "1. Cookies:<br>" +
            "Unsere Website verwendet Cookies, um Ihr Surferlebnis zu verbessern. Diese helfen uns, die Website zu optimieren und Ihnen relevante Inhalte bereitzustellen. Weitere Informationen finden Sie in unserer Cookie-Richtlinie.<br>" +
            "<br>" +
            "2. Datenschutzerklärung:<br>" +
            "Bitte lesen Sie unsere Datenschutzerklärung, um zu verstehen, wie wir Ihre persönlichen Daten sammeln, verwenden und schützen. Ihre Privatsphäre ist uns wichtig, und wir setzen alles daran, sie zu wahren.<br>" +
            "<br>" +
            "3. Nutzungsbedingungen:<br>" +
            "Die Nutzung unserer Website unterliegt unseren Nutzungsbedingungen. Diese legen die Bedingungen für die Nutzung unserer Dienste fest. Bitte lesen Sie diese sorgfältig durch.<br>" +
            "<br>" +
            "Durch Klicken auf \"Akzeptieren\" stimmen Sie Folgendem zu:<br>" +
            "<br>" +
            "<ul>" +
            "<li>Der Verwendung von Cookies gemäß unserer Cookie-Richtlinie</li>" +
            "<li>Unseren <a target='_blank' href='/datenschutz'>Datenschutzbestimmungen</a></li>" +
            "<li>Unseren <a target='_blank' href='/nutzungsbedingungen'>Nutzungsbedingungens</a></li>" +
            "</ul>",
        buttonText: "Akzeptieren",
        footer: "<a target='_blank' href='/nutzungsbedingungen'>Nutzungsbedingungen</a><br><a target='_blank' href='/datenschutz'>Datenschutzerklärung</a><br><a target='_blank' href='/impressum'>Impressum</a>"
    },

    faq: [
        {
            title: "Was ist Portfoglio und was kann es?",
            content: `
            Portfoglio ist eine Plattform zur KI-gestützten, technischen und fachlichen Analyse von Wertpapieren (zur Zeit nur Einzelaktien).
            Die Analyse basiert auf Daten aus folgenden Bereichen:<br><br>
            - Kennzahlen: Die Kennzahlen (wie z.B. KGV, KBV, ROI, PEG uvm.) werden von einer KI im Kontext vergleichbarer Unternehme bewertet. 
            Ein tieferes Verständnis dieser Werte kann nützlich sein, ist aber nicht zwingend erforderlich.<br><br>
            - Trends: Wir ordnen die vergangenen Trends ein und treffen Vorhersagen für mögliche Trendentwicklungen mit Hilfe des "Prophet"-Algorithmus von Meta (Facebook) unter 
            Berücksichtigungung von saisonalen Ereignissen.<br><br>
            - Kursziele: Um eine möglichst realistische Einschätzung zu Kurszielen zu geben, bedienen wir uns drei verschieden Datenquellen.
            Zum einen nutzen wir hier die öffentlich einsehbaren Kursziele von Analysten und zum anderen bestimmen wir Kursziele, wie auch schon die Trends,
            durch Metas "Prophet"-Algorithmus. Darüber hinaus haben wir für einen dritten Vergleichswert ein eigenes rekurrentes neuronales Netz des Typs LSTM entwickelt,
            welches eine rein technische Analyse des Kursverlaufs vornimmt. Dieses Feature befindet sich noch in einer späten Entwicklungsphase und um eine möglichst gute
            Qualität der Daten aus der KI zu gewährleisten wird dieses Feature erst im Laufe der nächsten Monate freigeschaltet.<br><br>
            - Nachrichten: Um auch aktuelle Unternehmensentwicklungen, welche oft Einfluss auf den Kurs einer Aktie haben, in die KI-basierte Bewertung einfließen zu lassen,
            analysiert unsere KI Neuigkeiten zu einer Aktie im Stundentakt und wertet diese in Bezug auf den möglichen Einfluss auf den Kurs.<br><br>
            - Analystenbewertungen: Wir beziehen aus verschiedenen Quellen Analystenbewertungen, welche wir für euch zusammenführen, aufbereiten und bewerten.
            `
        },
        {
            title: "Wie bediene ich Portfoglio und wie funktioniert die Analyse?",
            content: `
            Die Bedienung von Portfoglio ist denkbar einfach: du suchst nach dem Namen, der ISIN oder der WKN einer Aktie und wählst diese aus.
            Danach wird dir die komplette Analyse angezeigt und du kannst die Aktie zu deiner Watchliste, welche dir direkt auf der Startseite nach dem Login
            angezeigt wird, hinzufügen, um sie immer im Blick zu haben und direkt aufrufen zu können. Wird eine Aktie in einem bestimmten Zeitraum zum ersten
            Mal aufgerufen, können ggf. längere Ladezeiten von bis zu einer Minute auftreten, da in diesem Fall die gesamte Neuanalyse der Aktie angestoßen wird.
            Du findest anschließend eine Gesamtbewertung der Aktie im Bereich von 0-10, welche zusätzlich auf die einzelnen Bewertungskriterien und Werte aufschlüsselt wird.
            Als kommendes Feature ist auch ein tägliches Update über die Aktien in deiner Watchlist per Mail oder SMS geplant.<br><br>
            Die KI-gestützten Analysen werden mit Hilfe von OpenAI Modellen, des "Prophet"-Algorithmus von Meta, sowie einigen Portfoglio KI Modellen erstellt.
            `
        },
        {
            title: "Was kostet der Service?",
            content: `
            Portfoglio ist in der Beta Version ein komplett kostenfreier Service. Es ist jedoch möglich, dass in Zukunft einige Features in der kostenfreien Version
            eingeschränkt werden, um die Kosten auf unserer Seite decken zu können. Durch eine Anmeldung in der Beta Phase wirst du jedoch dauerhaft von reduzierten Angeboten
            profitieren können.
            `
        },
        {
            title: "Wann kann ich mich einloggen?",
            content: `
            Da sich Portfoglio momentan noch in der Alpha Phase befindet, ist die Nutzung momentan noch nicht nicht möglich. Der Start der Beta ist für Mitte Dezember 
            diesen Jahres geplant. Wenn du dich registriert hast, werden wir dich informieren, sobald der Zugang freigeschaltet ist. Falls du Portfoglio früher nutzen 
            und am Test der Alphaversion teilnehmen möchtest, kannst du dich gerne über Instagram bei uns unter
            Angabe deiner genutzen Mailadresse melden und wir werden prüfen, ob wir noch Platz haben. 
            `
        },
        {
            title: "Kann mir eine Rendite garantiert werden?",
            content: `
            Da wir keine verbindlichen Kaufempfehlungen abgeben, sondern nur eine Einschätzung auf Basis der uns vorliegenden Daten vornehmen
            und keinen Einfluss auf unerwartete Entwicklung am Markt oder im Unternehmen haben, können wir leider keine Renditen garantieren. 
            Vor einem Investment ist Portfoglio aber eine geeignete Quelle für eine objektive Einschätzung des Status Quo.
            `
        },
        {
            title: "Seid ihr ein Broker? / Kann man über euch Aktien kaufen?",
            content: `
            Nein, über Portfoglio können keinerlei Investments vorgenommen werden. Die Plattform dient ausschließlich dazu, eine Einschätzung zu
            einem Investment zu erhalten.
            `
        },
        {
            title: "Gibt es eine App?",
            content: `
            Eine App für iOS und Android befindet zur Zeit in Entwicklung und wird voraussichtlich nach Ende der Beta Phase in den App Stores erhältlich sein.
            `
        },
    ]
}