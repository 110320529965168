import React, {useEffect, useState} from "react";
import './VerticalSelect.scss';

function VerticalSelect(props: any) {
    const {data, onselect, defaultValue} = props;
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleSelect = (value: any) => {
        setValue(value);
        onselect(value);
    };

    const renderOptions = () => {
      return data.map((item: KeyValue, index: number) => {
          return <div key={index} onClick={() => handleSelect(item.value)} className={value === item.value ? "selected" : ""}>{item.key}</div>
      })
    };

    return(
        <div className="selection">
            {renderOptions()}
        </div>
    );
}

export default VerticalSelect;

export interface KeyValue{
    key: string;
    value: any;
}