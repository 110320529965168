import StockChart from "../../components/StockChart/StockChart";
import React, {useEffect, useState} from "react";
import './AnalysisPage.scss';
import VerticalSelect from "../../components/VerticalSelect/VerticalSelect";
import {getAssetData, getProphetData, getTimeSeries} from "../../utils/DataUtils";
import {TimeSeries} from "../../models/TimeSeries";
import {useMsal} from "@azure/msal-react";
import {ProphetData} from "../../models/ProphetData";
import RatingOverview from "../../components/RatingOverview/RatingOverview";
import {AssetData, Indicators} from "../../models/AssetData";
import {OverviewDataRating} from "../../models/OverviewDataRating";
import {rateIndicators, rateTargetPrice, rateTrend} from "../../utils/RatingUtils";
import DataSection from "../../components/DataSection/DataSection";
import AssetDataMockResponse from  "../../mocks/AssetDataMockResponse.json";
import TimeSeriesMockResponse from "../../mocks/TimeSeriesMockResponse.json";
import ProphetDataMockResponse from "../../mocks/ProphetDataMockResponse.json";
import {config} from "../../config";

function AnalysisPage() {
    const isin = "US0378331005";

    const [prophetData, setProphetData] = useState<ProphetData[]>([]);
    const [realData, setRealData] = useState<TimeSeries[]>([]);
    const [assetData, setAssetData] = useState<AssetData>();
    const [overviewRating, setOverviewRating] = useState<OverviewDataRating>();
    const [currentPrice, setCurrentPrice] = useState<number>(0);
    const [movement, setMovement] = useState<number[]>([]);

    const [selectedTimeframe, setSelectedTimeframe] = useState("MAX");

    const {instance} = useMsal();
    const token = instance.getActiveAccount()?.idToken ?? "";

    const timeframeOptions = [
        {key: "1T", value: "TWO_DAYS"},
        {key: "1W", value:"ONE_WEEK"},
        {key: "1M", value:"ONE_MONTH"},
        {key: "3M", value:"THREE_MONTHS"},
        {key: "6M", value:"SIX_MONTHS"},
        {key: "1Y", value:"ONE_YEAR"},
        {key: "MAX", value:"MAX"}
    ]


    // get Data
    useEffect(() => {
        console.log(config);
        if(config.mockAssetData) {
            setAssetData(AssetDataMockResponse);
        } else {
            getAssetData(isin, selectedTimeframe, token).then(data => setAssetData(data)).catch(() => instance.logout());
        }
        if(config.mockProphetData) {
            setProphetData(ProphetDataMockResponse as unknown as ProphetData[]);
        } else {
            getProphetData(isin, selectedTimeframe, token).then(data => setProphetData(data)).catch(() => instance.logout());
        }
        if(config.mockTimeSeries) {
            setRealData(TimeSeriesMockResponse as unknown as TimeSeries[]);
        } else {
            getTimeSeries(isin, selectedTimeframe, token).then(data => setRealData(data)).catch(() => instance.logout());
        }
    }, [selectedTimeframe]);

    useEffect(() => {
        if(realData && realData[0]) {
            setCurrentPrice(realData[0].dataPoints.pop()?.midPrice ?? 0);
        }
    }, [realData]);

    useEffect(() => {
        if(realData && realData[0]) {
            let crPrice = realData[0]?.closingReferencePoint.midPrice ?? 0;
            let absolute = Math.round((currentPrice - crPrice) * 100) / 100;
            let relative = Math.round((((currentPrice / crPrice) * 100) - 100) * 100) / 100;
            setMovement([relative, absolute])
        }
    }, [currentPrice, realData]);

    useEffect(() => {
        if(realData && realData[0] && prophetData && prophetData[0] && assetData && selectedTimeframe === "MAX") {
            let realDataPoint = realData[0].dataPoints[realData[0].dataPoints.length - 1].midPrice;
            let prophetDataPoint = prophetData[prophetData.length - 1];
            let trendRating = rateTrend(realDataPoint, {low: prophetDataPoint.trend_lower, avg: prophetDataPoint.trend, high: prophetDataPoint.trend_upper});
            let indicatorsRating = rateIndicators(assetData?.indicators ?? {} as Indicators);
            let kiTargetPriceRating = rateTargetPrice(realDataPoint, prophetDataPoint.yhat ?? 0);
            let analystTargetPriceRating = rateTargetPrice(realDataPoint, assetData?.analystRecommendations.avgTargetPrice ?? 0);
            setOverviewRating({
                ...overviewRating,
                trend: trendRating,
                indicators: indicatorsRating,
                analystTargetPrice: Math.round(analystTargetPriceRating * 10) / 10,
                kiTargetPrice: Math.round(kiTargetPriceRating * 10) / 10} as OverviewDataRating);
        }
    }, [realData, prophetData, assetData])

    const getColorFromRating = (rating: number): string => {
        if(rating >= 7) {
            return "var(--primary)";
        }
        if(rating <= 3) {
            return "var(--secondary)";
        }
        return "var(--tertiary)";
    }

    const renderKennzahlenGrid = () => {
        const kennzahlenMap: {key: string, value: any}[] = [
            {
                key: "KGV",
                value: assetData?.indicators.kgv
            },
            {
                key: "KBV",
                value: assetData?.indicators.kbv
            },
            {
                key: "KCV",
                value: assetData?.indicators.kcv
            },
            {
                key: "KUV",
                value: assetData?.indicators.kgv
            },
            {
                key: "Marktkapitalisierung",
                value: assetData?.indicators.marketcap
            },
            {
                key: "Dividendenrendite",
                value: "0.0%"
            },
            {
                key: "Beta 1J",
                value: 1
            },
            {
                key: "KG-Wachstum (PEG Ratio)",
                value: assetData?.indicators.peRatio
            },
            {
                key: "Return on Investment (ROI)",
                value: assetData?.indicators.returnOnInvestment
            },

        ]
        return (
            <div className="kennzahlen-grid">
                {kennzahlenMap.map(({key, value}) =>
                    <div key={key}>
                        <div>{key}</div>
                        <div><span style={value?.value ? {backgroundColor: getColorFromRating(value.rating)} : {color: "var(--text-color)"}}>{value?.value ? value.value : value}</span></div>
                    </div>
                )}
            </div>
        );
    }

    return(
        <div className="page">
            <div>
                <div className="current-data">
                    <div className="name">{assetData?.name}</div>
                    <div className="market-price">{currentPrice + "€"}</div>
                    <div className={"movement " + (movement[0] > 0 ? "positive" : "negative")}>{movement[0] + "%"}</div>
                    <div className={"movement " + (movement[1] > 0 ? "positive" : "negative")}>{movement[1] + "€"}</div>
                </div>
                <div className="stock-chart">
                    <StockChart realData={realData} prophetData={prophetData}></StockChart>
                </div>
                <div className="vertical-selection">
                    <VerticalSelect data={timeframeOptions} negative={true} onselect={(value: string) => setSelectedTimeframe(value)} defaultValue="MAX"></VerticalSelect>
                </div>
            </div>
            <div className="overview">
                <RatingOverview ratedData={overviewRating}/>
            </div>
            <DataSection title={"Kennzahlen " + (overviewRating?.indicators ?? "")}>
                {renderKennzahlenGrid()}
            </DataSection>
            <DataSection title={"Analystenbewertungen " + (overviewRating?.analystRecommendations ?? "")}>

            </DataSection>
            <DataSection title={"Kursziele"}>
                <div className="targetprices">
                    <h3>{"KI Kursziele " + (overviewRating?.kiTargetPrice ?? "")}</h3>
                    <h3>{"Analysten Kursziele " + (overviewRating?.analystTargetPrice ?? "")}</h3>
                </div>
            </DataSection>
            <DataSection title={"News " + (overviewRating?.news ?? "")}>

            </DataSection>
            <DataSection title="Dividenden">

            </DataSection>
        </div>
    );
}

export default AnalysisPage;