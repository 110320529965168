import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";
import {BrowserRouter} from "react-router-dom";
import {MsalProvider} from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/*
msalInstance.addEventCallback((event) => {
    if(event.eventType === EventType.INITIALIZE_END) {
        msalInstance.acquireTokenSilent(loginRequest).then().catch(() => msalInstance.logout());
    }
});
*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <App/>
        </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
