import React, {useState} from "react";
import './Accordion.scss';

function Accordion(props: any) {
    const {title, children} = props;

    const [open, setOpen] = useState<boolean>(false);

    return(
        <div className={open ? "accordion open" : "accordion"}>
            <div className="header" onClick={() => setOpen(!open)}>
                <div className="title">{title}</div>
                <div className="arrow">►</div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: children}}>
            </div>
        </div>
    );
}

export default Accordion;