function Nutzungsbedingungen() {
    return(
        <div style={{padding: "20px"}}>
            <h1>Nutzungsbedingungen für "Portfoglio" - KI Aktienanalyse Tool</h1>

            <p>Letztes Update: 18.11.2023</p>

            <h2>1. Haftungsausschluss für Analysen und Ergebnisse:</h2>
            <p>Portfoglio bietet eine automatisierte Aktienanalyse auf Grundlage von Künstlicher Intelligenz an. Die erzeugten Analysen und Ergebnisse dienen ausschließlich zu Informationszwecken. Wir übernehmen keine Gewähr für die Richtigkeit, Vollständigkeit oder Aktualität der bereitgestellten Informationen. Jegliche Handlungen auf Grundlage dieser Analysen geschehen auf eigenes Risiko.</p>

            <h2>2. Haftungsausschluss für abrufbare Daten:</h2>
            <p>Portfoglio greift auf externe Datenquellen für aktuelle Marktdaten und Informationen zu. Wir übernehmen keine Haftung für die Richtigkeit, Verfügbarkeit oder Vollständigkeit dieser Daten. Nutzer sind angehalten, die Informationen eigenständig zu verifizieren, bevor sie auf deren Grundlage Entscheidungen treffen.</p>

            <h2>3. Keine Finanzberatung:</h2>
            <p>Portfoglio stellt keine Finanzberatung dar. Die bereitgestellten Analysen und Informationen sind nicht als Empfehlung zum Kauf oder Verkauf von Finanzinstrumenten zu verstehen. Nutzer sollten sich bei Bedarf von einem qualifizierten Finanzberater beraten lassen.</p>

            <h2>4. Eigenverantwortlichkeit:</h2>
            <p>Die Nutzung von Portfoglio erfolgt auf eigene Verantwortung. Nutzer sind selbst dafür verantwortlich, ihre eigenen Anlageentscheidungen zu treffen und die damit verbundenen Risiken zu tragen. Portfoglio übernimmt keine Haftung für etwaige Verluste oder Schäden, die durch die Nutzung des Dienstes entstehen.</p>

            <h2>5. Änderungen der Nutzungsbedingungen:</h2>
            <p>Portfoglio behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Nutzer werden über Änderungen rechtzeitig informiert. Die fortgesetzte Nutzung des Dienstes nach Bekanntgabe von Änderungen gilt als Zustimmung zu den aktualisierten Bedingungen.</p>

            <h2>6. Schlussbestimmungen:</h2>
            <p>Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, berührt dies nicht die Wirksamkeit der übrigen Bestimmungen. An die Stelle der unwirksamen Bestimmung tritt eine rechtlich zulässige Regelung, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommt.</p>

        </div>
    )
}

export default Nutzungsbedingungen;