import React from "react";
import "./Navbar.scss";
//import { ReactComponent as Logo } from '../../assets/logo.svg';
import Logo from '../../assets/logo.svg';
import {Link} from "react-router-dom";


function Navbar() {
    return(
        <Link to="/" className="navbar">
            <img src={Logo} alt="logo"/>
            <span>Portfoglio</span>
        </Link>
    )
}

export default Navbar;