function Impressum() {
    return(
        <div style={{padding: "20px"}}>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Justin Jeremias Gro&szlig;e<br />
                Suderburger Wende 18<br />
                30625 Hannover</p>

            <h2>Kontakt</h2>
            <p>Telefon: 01755071640<br />
                E-Mail: info@portfogl.io</p>

        </div>
    )
}

export default Impressum;