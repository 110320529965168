import React from "react";
import './Footer.scss';

function Footer() {
    return(
        <div className="footer">
            <a href='/datenschutz' target='_blank'>Datenschutzerklärung</a>
            <a href='/impressum' target='_blank'>Impressum</a>
            <a href='/nutzungsbedingungen' target='_blank'>Nutzungsbedingungen</a>
            <p>©Justin Grosse</p>
        </div>
    );
}

export default Footer;