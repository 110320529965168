import {Indicators} from "../models/AssetData";

export function rateTrend(realDataPoint: number, prophetDataPoint: {low: number, avg: number, high: number}): number {
    let low = Math.round(((prophetDataPoint.low / realDataPoint) - 1) * 100);
    let avg = Math.round(((prophetDataPoint.avg / realDataPoint) - 1) * 100);
    let high = Math.round(((prophetDataPoint.high / realDataPoint) - 1) * 100);

    low = 2.5 * low;
    avg = 5 * avg;
    high = 2.5 * high;

    //return (low + avg + high) / 10;
    return 10;
}

//TODO: refactor
export function rateIndicators(indicators: Indicators) {
    if(!indicators?.kgv?.rating) {
        return 0;
    }
    return (indicators.kgv.rating + indicators.kbv.rating + indicators.kcv.rating + indicators.kuv.rating) / 4;
}

export function rateTargetPrice(realPrice: number, targetPrice: number) {
    return (((targetPrice / realPrice) - 1) * 10) + 5;
}