import React from "react";
import './PreLandingPage.scss';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import MockUp from "../../assets/mockup.png";
import Accordion from "../../components/Accordion/Accordion";
import {config} from "../../config";
import {loginRequest} from "../../authConfig";
import OpenAiBadge from "../../assets/openaibadge.svg";
import MetaLogo from "../../assets/metalogo.png";
import Footer from "../../components/Footer/Footer";

function PreLandingPage() {
    const {instance} = useMsal();

    const renderFaqElements = () => {
        return(
            <>
            {
                config.faq.map(element =>
                    <Accordion key={element.title} title={element.title}>{element.content}</Accordion>
                )
            }
            </>
        );
    }

    return(
        <>
            <AuthenticatedTemplate>
                <div className="pre-landing-page">
                    <div className="circle-container">
                        <div className="circle-wrapper circle-wrapper right">
                            <div className="circle right"></div>
                        </div>
                        <div className="circle-wrapper circle-wrapper left">
                            <div className="circle left"></div>
                        </div>
                        <div className="circle-checkmark">
                        </div>
                    </div>
                    <h1 style={{textAlign: "center"}}>Registrierung erfolgreich!</h1>
                    <h2 style={{textAlign: "center"}}>Wir halten euch auf dem Laufenden und informieren euch sobald ihr euch einloggen könnt!</h2>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="pre-landing-page">
                    <div className="ad-text-container">
                        <div className="ad-text">
                            <h1 className="green">Smarter investieren, nicht härter</h1>
                            <h1 className="white">Künstliche Intelligenz für deine besten Entscheidungen</h1>
                            <button onClick={() => instance.loginRedirect(loginRequest)}>Für die Beta registrieren</button>
                            <p>Jetzt für die kostenlose Beta registrieren, als erster Zugang bekommen und exklusive Vorteile genießen!</p>
                        </div>
                        <div className="ad-pic">
                            <img src={MockUp} alt="mockup"/>
                        </div>
                    </div>
                    <div className="powered">
                        <img className="open-ai" src={OpenAiBadge} alt="Powered by OpenAI"/>
                        <div className="meta">
                            <p>Powered by</p>
                            <img src={MetaLogo} alt={"Meta"}/>
                            <p>Technologies</p>
                        </div>
                    </div>
                    <div className="faq-container">
                        <h1>FAQ</h1>
                        <div>
                            {renderFaqElements()}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </UnauthenticatedTemplate>
        </>
    );
}

export default PreLandingPage;