import {TimeSeries} from "../models/TimeSeries";
import {TimeSeriesDTO} from "../models/TimeSeriesDTO";
import {ProphetData} from "../models/ProphetData";
import {AssetData} from "../models/AssetData";

export function getTimeSeries(isin: string, timeframe: string, token: string): Promise<TimeSeries[]> {
    return new Promise<TimeSeries[]>((resolve, reject) => {
        fetch('http://localhost:8089/api/timeseries/' + isin + '/' + timeframe, {
            headers: {"Authorization": "Bearer " + token}
        }).then(response => {
            if(response.status === 200) {
                response.json().then(data => resolve((data as TimeSeriesDTO).timeSeriesBySecurity));
            }
        });
    });
}

export function getProphetData(isin: string, timeframe: string, token: string): Promise<ProphetData[]> {
    return new Promise<ProphetData[]>((resolve, reject) => {
        fetch('http://localhost:8086/api/prophet/' + isin + '/' + timeframe, {
            headers: {"Authorization": "Bearer " + token}
        }).then(response => {
            if(response.status === 200) {
                response.json().then(data => resolve(data));
            }
        });
    });
}

export function getAssetData(isin: string, timeframe: string, token: string): Promise<AssetData> {
    return new Promise<AssetData>((resolve, reject) => {
        fetch('http://localhost:8087/api/assetdata/' + isin, {
            headers: {"Authorization": "Bearer " + token}
        }).then(response => {
            if(response.status === 200) {
                response.json().then(data => resolve(data));
            }
        });
    });
}