import React, {useContext, useEffect, useState} from "react";
import './Modal.scss';
import {ModalContext} from "../../App";
import {useLocation} from "react-router-dom";

function Modal() {
    const modalContext = useContext(ModalContext);
    const location = useLocation();
    const [visible, setVisible] = useState<boolean>(false);
    const noModalPaths = ['/datenschutz', '/nutzungsbedingungen', '/impressum']

    useEffect(() => {
        setVisible(!noModalPaths.includes(location.pathname) && !!modalContext.show);
    }, [location.pathname, modalContext]);

    const renderButtons = () => {
        if(modalContext.buttonText instanceof Array && modalContext.buttonCallback instanceof Array) {
            return(
                <>
                    {
                        //TODO: implement array button callback
                        (modalContext.buttonText as string[]).map((text, index) =>
                            <button>{text}</button>
                        )
                    }
                </>
            )
        }

        if(modalContext.buttonText && !(modalContext.buttonCallback instanceof Array)) {
            return(
              <button onClick={modalContext.buttonCallback}>{modalContext.buttonText}</button>
            );
        }
    }

    return(
        <>
            {
                visible &&
                <div className="modal-container">
                    <div className="modal">
                        <p dangerouslySetInnerHTML={{__html: modalContext.text ?? ""}}/>
                        <div className="modal-footer" dangerouslySetInnerHTML={{__html: modalContext.footer ?? ""}}/>
                        <div className="buttons">
                            {
                                renderButtons()
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Modal;