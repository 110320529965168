import React, {useEffect, useState} from "react";
import {ProphetData} from "../../models/ProphetData";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale, LineController,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import './StockChart.scss';
import {TimeSeriesDataPoint} from "../../models/TimeSeriesDataPoint";

function StockChart(props: any) {
    const {realData, prophetData} = props;
    const [chartData, setChartData] = useState<any>({});

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController
    );

    useEffect(() => {
        if(!prophetData || !prophetData.length || prophetData.length < 1 || !realData || !realData.length || realData.length < 1) {
            return;
        }
        const labels = prophetData.map((data: ProphetData) => data.ds.toString());
        const offset= realData[0].dataPoints[0].midPrice - prophetData[0].trend;
        const positive = (realData[0].dataPoints[0].midPrice - realData[0].dataPoints[realData[0].dataPoints.length - 1].midPrice) <= 0;
        setChartData({
            labels,
            datasets: [
                {
                    type: 'line' as const,
                    label: 'Trend (Prophet KI Modell)',
                    borderColor: 'rgb(7,98,253)',
                    borderWidth: 2,
                    fill: false,
                    data: prophetData.map((data: ProphetData) => data.trend + offset),
                },
                /*
                {
                    type: 'line' as const,
                    label: 'Vorhersage (Prophet KI Modell)',
                    borderColor: 'rgba(255,2,2,0.2)',
                    borderWidth: 2,
                    fill: false,
                    data: prophetData.map(data => data.yhat),
                },
                */
                {
                    type: 'line' as const,
                    label: 'Kurs',
                    borderColor: positive ? 'rgb(3,252,203)' : 'rgb(253, 82, 82)',
                    borderWidth: 2,
                    fill: false,
                    data: realData[0].dataPoints.map((data: TimeSeriesDataPoint) => data.midPrice),
                },
            ]
        });
    }, [prophetData, realData]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Kursentwicklung',
            },
            tooltip: {
                enabled: true,
                interaction: {
                    mode: "index",
                    intersect: false
                }
            }
        },
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        scales: {
            x: {
                ticks: {
                    display: false
                }
            }
        }
    };

    return (
        <div className="stock-chart-container">
            {
                chartData && chartData.datasets && chartData.datasets.length > 0 &&
                <Line options={options} data={chartData} />
            }
        </div>
    );
}

export default StockChart;