import React from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {PolarArea} from "react-chartjs-2";
import './RatingOverview.scss';

function RatingOverview(props: any) {
    const {ratedData} = props;
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    const data = {
        labels: ['Trend', 'News', 'Analystenbewertung', 'Kennzahlen', 'Analystenkursziel', 'KI Kursziel'],
        datasets: [
            {
                label: 'Bewertung',
                data: [ratedData?.trend ?? 0, 9, 6, ratedData?.indicators ?? 0, ratedData?.analystTargetPrice ?? 0, ratedData?.kiTargetPrice ?? 0],
                backgroundColor: [
                    'rgba(7,98,253, 0.8)', // Blaugrün
                    'rgba(255, 153, 51, 0.8)', // Orange
                    'rgba(204, 0, 102, 0.8)', // Weinrot
                    'rgba(102, 255, 102, 0.8)', // Leuchtendes Grün
                    'rgba(255, 102, 255, 0.5)', // Pink
                    'rgba(255,246,0,0.5)' // Pink
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'right' as const
            }
        }
    };

    return(
        <div className="container">
            <PolarArea data={data} options={options}/>
            <h1>Gesamtwertung: <span className="positive">8.7</span></h1>
        </div>
    )
}

export default RatingOverview;